.Infrastructure {
  .cleanup {
    display: flex;
    justify-content: center;

    button {
      height: 50px;
      width: 300px;
    }
  }

  &__textarea {
    height: 800px !important;
    background-color: #000 !important;
    border: 2px solid #000 !important;
    color: #00ff00 !important;
    padding: 8px !important;
    font-family: courier new;
    margin-top: 20px;
  }

  &__spinner {
    height: 800px !important;
    color: #00ff00 !important;

    padding: 8px !important;
    font-family: courier new;
    margin-top: 20px;
  }
}

@font-family: 'Lato', sans-serif;@primary-color: #5E81AC;@success-color: #A3BE8C;@error-color: #BF616A;@info-color: #5E81AC;@black: #2E3440;@white: #ECEFF4;@body-background: #ECEFF4;@layout-sider-background: #5E81AC;@menu-dark-bg: #5E81AC;@menu-dark-inline-submenu-bg: #5E81AC;