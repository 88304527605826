.roles-tag {
  display: inline-flex;
  align-items: center;
  gap: 4px;

  &-icon {
    width: 16px;
    height: 9px;
  }

  &-tooltip {
    display: flex;
    flex-direction: column;

    &-content-wrapper {
      display: inline-flex;
      align-items: center;
      gap: 4px;
    }
  }
}

@font-family: 'Lato', sans-serif;@primary-color: #5E81AC;@success-color: #A3BE8C;@error-color: #BF616A;@info-color: #5E81AC;@black: #2E3440;@white: #ECEFF4;@body-background: #ECEFF4;@layout-sider-background: #5E81AC;@menu-dark-bg: #5E81AC;@menu-dark-inline-submenu-bg: #5E81AC;