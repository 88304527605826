.option__wrapper {
  display: flex;
  gap: 8px;

  img {
    width: 16px;
  }
}

.form-item {
  &__wrapper {
    .ant-form-item-row {
      //
      display: unset;
    }

    .ant-form-item-label {
      //   min-width: 50%;
    }
  }
}

.vendor-configurations {
  &__wrapper {
    margin-block-end: 16px;
  }
}

.credentials-configurations {
  &__details {
    margin-block-start: 16px;
  }
  &__row-1 {
    display: grid;
    grid-template-columns: 100px 1fr 30px;
    align-items: center;
    margin-block: 16px;
  }
  &__row-2 {
    display: grid;
    grid-template-columns: 100px 1fr 30px;
    align-items: center;
    margin-block: 16px;
  }
}
.chidori__wrapper {
  .copy-icon.disabled {
    cursor: no-drop;
  }
}

@font-family: 'Lato', sans-serif;@primary-color: #5E81AC;@success-color: #A3BE8C;@error-color: #BF616A;@info-color: #5E81AC;@black: #2E3440;@white: #ECEFF4;@body-background: #ECEFF4;@layout-sider-background: #5E81AC;@menu-dark-bg: #5E81AC;@menu-dark-inline-submenu-bg: #5E81AC;