.copiedText {
  height: 40px;
  opacity: 20px;
  margin-bottom: 20px;

  &__custom-warning {
    font-size: 12px;
    text-align: center;
    background-color: #eef0f2;
    padding: 10px;
    border-radius: 5px;
    svg {
      color: orange;
      font-size: 15px;
      margin-right: 6px;
    }
  }
}

@font-family: 'Lato', sans-serif;@primary-color: #5E81AC;@success-color: #A3BE8C;@error-color: #BF616A;@info-color: #5E81AC;@black: #2E3440;@white: #ECEFF4;@body-background: #ECEFF4;@layout-sider-background: #5E81AC;@menu-dark-bg: #5E81AC;@menu-dark-inline-submenu-bg: #5E81AC;