@import '~styles/variables';

.cluster-modal {
  &-title {
    display: flex;
    flex-direction: column;
  }
  &-toggles {
    &__container {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      grid-template-rows: 1fr 1fr;
      gap: 0px 0px;
      grid-auto-flow: row;
      grid-template-areas:
        'welcome welcome chat chat homepage homepage'
        'ondemand ondemand notebook notebook consumptionAlerts consumptionAlerts';

      .welcome {
        grid-area: welcome;
      }

      .chat {
        grid-area: chat;
      }

      .homepage {
        grid-area: homepage;
      }

      .ondemand {
        grid-area: ondemand;
      }

      .notebook {
        grid-area: notebook;
      }

    .consumptionAlerts { 
      grid-area: consumptionAlerts;
    }
    }

    &__item {
      padding: 2px;
      label {
        font-size: 13px;
      }
    }
  }

  &__libraries-form-item {
    &__hint {
      font-size: 12px;
      margin-bottom: 22px;
    }

    &__tab-link {
      display: flex;
      align-items: center;
      gap: 8px;
      svg {
        color: @link-color;
      }
    }
  }
}

@font-family: 'Lato', sans-serif;@primary-color: #5E81AC;@success-color: #A3BE8C;@error-color: #BF616A;@info-color: #5E81AC;@black: #2E3440;@white: #ECEFF4;@body-background: #ECEFF4;@layout-sider-background: #5E81AC;@menu-dark-bg: #5E81AC;@menu-dark-inline-submenu-bg: #5E81AC;