@import '~styles/variables';

.operators-step {
  &__platform-select-label {
    display: flex;
    gap: 8px;
    align-items: center;

    &.disabled {
      svg {
        filter: grayscale(80%);
      }
    }
  }
  &__operators-form-item {
    .ant-table-content {
      max-height: 400px;
      border: 1px solid @antd-table-border-color;
    }
  }
  &__node-labels-cell-wrapper {
    display: flex;
    justify-content: center;
    align-items: baseline;
  }
}

@font-family: 'Lato', sans-serif;@primary-color: #5E81AC;@success-color: #A3BE8C;@error-color: #BF616A;@info-color: #5E81AC;@black: #2E3440;@white: #ECEFF4;@body-background: #ECEFF4;@layout-sider-background: #5E81AC;@menu-dark-bg: #5E81AC;@menu-dark-inline-submenu-bg: #5E81AC;