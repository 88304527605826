.create-clusters {
  &__add-new-button {
    border: 1px solid #979797;
    padding: 18px 20px;
    font-size: 16px;
    font-weight: 600;
    color: #2e3440d9;
    display: flex;
    justify-content: flex-end;
  }
}

@font-family: 'Lato', sans-serif;@primary-color: #5E81AC;@success-color: #A3BE8C;@error-color: #BF616A;@info-color: #5E81AC;@black: #2E3440;@white: #ECEFF4;@body-background: #ECEFF4;@layout-sider-background: #5E81AC;@menu-dark-bg: #5E81AC;@menu-dark-inline-submenu-bg: #5E81AC;