@import '~styles/variables';

.clusters {
  td {
    min-width: 80px;
  }
  .cluster-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .num-of-selected {
      border: 1px solid #d4d4d4;
      padding: 3px;
      border-radius: 4px;
      .anticon-close {
        font-size: 11px;
        cursor: pointer;
        margin-left: 4px;
      }
    }
  }
  .search-field {
    display: flex;
    button {
      height: 32px;
    }
    .ant-btn-primary {
      margin-right: 5px;
    }
  }

  &__size-cell {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 8px;
    &.center {
      justify-content: center;
    }
  }

  &__advanced-search-platform-select-label {
    display: flex;
    align-items: center;
    gap: 4px;
    font-weight: 600;
    color: @black;
    line-height: 26px;
  }
  &__status-tag {
    border-radius: 12px;
    display: inline-flex;
    gap: 8px;
    align-items: center;

    &-loading,
    &-stopping,
    &-disconnected {
      border: 1px solid;
      width: 12px;
      height: 12px;
      border-radius: 50%;
    }
    &-loading {
      background: linear-gradient(to bottom, transparent 50%, @loading 50%);
    }
    &-stopping {
      background: linear-gradient(to top, transparent 50%, @loading 50%);
    }
  }
  &__platform-select-label {
    display: flex;
    align-items: center;
    gap: 4px;
    color: @black;
    line-height: 26px;
  }
  &__platform-select-img,
  &__region-select-img {
    height: 16px;
  }
  &__advanced-search-region-select-label {
    display: flex;
    align-items: center;
    gap: 4px;
    font-weight: 600;
    color: @black;
    line-height: 26px;
    border-block-start: 1px solid @slate-gray;
    border-block-end: 1px solid @solitude-gray;
  }

  &__region-select-item {
    .ant-select-item-option-content {
      display: flex;
      justify-content: space-between;
    }
  }
}

.chidori-modal,
.notebook-modal {
  &__title {
    display: flex;
    align-items: center;
    gap: 8px;

    img {
      width: 16px;
    }
  }
}

@font-family: 'Lato', sans-serif;@primary-color: #5E81AC;@success-color: #A3BE8C;@error-color: #BF616A;@info-color: #5E81AC;@black: #2E3440;@white: #ECEFF4;@body-background: #ECEFF4;@layout-sider-background: #5E81AC;@menu-dark-bg: #5E81AC;@menu-dark-inline-submenu-bg: #5E81AC;