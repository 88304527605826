.external-bucket-modal {
  &__wrapper {
    h3 {
      font-size: 16px;
      margin: unset;
    }
  }
  &__title {
    display: flex;
    gap: 8px;
    align-items: center;
    img {
      width: 16px;
    }
  }

  &__bucket-path-input-group.ant-input-group.ant-input-group-compact {
    display: flex;
  }

  &__bucket-path-section {
    margin-block: 16px;
  }
  &__switch-section,
  &__clusters-section {
    display: flex;
    align-items: center;
    gap: 4px;
    margin-block: 16px;
  }
  &__switch-section img {
    width: 14px;
  }
}

@font-family: 'Lato', sans-serif;@primary-color: #5E81AC;@success-color: #A3BE8C;@error-color: #BF616A;@info-color: #5E81AC;@black: #2E3440;@white: #ECEFF4;@body-background: #ECEFF4;@layout-sider-background: #5E81AC;@menu-dark-bg: #5E81AC;@menu-dark-inline-submenu-bg: #5E81AC;