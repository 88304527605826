.log-analyzer-modal {
  &__versions,
  &__regexs,
  &__services {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
    align-items: baseline;
  }

  &__services {
    line-height: 2.5715;
  }

  &__dates .ant-picker-range {
    display: flex;
  }
}

@font-family: 'Lato', sans-serif;@primary-color: #5E81AC;@success-color: #A3BE8C;@error-color: #BF616A;@info-color: #5E81AC;@black: #2E3440;@white: #ECEFF4;@body-background: #ECEFF4;@layout-sider-background: #5E81AC;@menu-dark-bg: #5E81AC;@menu-dark-inline-submenu-bg: #5E81AC;