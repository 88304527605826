.clusterStats {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 16px;
  padding-top: 10px;

  .count-info {
    display: flex;
    padding: 16px;
    align-items: center;
    gap: 5px;
    background-color: white;
    border-radius: 5px;
    .count {
      font-size: 30px;
      font-weight: bold;
      color: #777b82;
      display: block;
    }
  }
}
.errorBudget {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 16px;
  padding-top: 10px;

  .count-info {
    display: flex;
    padding: 16px;
    align-items: center;
    gap: 5px;
    background-color: white;
    border-radius: 5px;
    .count {
      font-size: 30px;
      font-weight: bold;
      color: #777b82;
      display: block;
    }
  }
}
.analytics {
  border-top: 5px solid;
  // #7976b7;
  // .anticon {
  //   background-color: #7976b7;
  // }
}
.loadJobs {
  border-top: 5px solid;
}

.mvs {
  border-top: 5px solid;
  // #4f97a1;

  // .anticon {
  //   background-color: #4f97a1;
  // }
}

.clusterSpecifier {
  padding-top: 10px;
}

@font-family: 'Lato', sans-serif;@primary-color: #5E81AC;@success-color: #A3BE8C;@error-color: #BF616A;@info-color: #5E81AC;@black: #2E3440;@white: #ECEFF4;@body-background: #ECEFF4;@layout-sider-background: #5E81AC;@menu-dark-bg: #5E81AC;@menu-dark-inline-submenu-bg: #5E81AC;