@import '~styles/variables';

.node-labels-renderer {
  &__dropdown {
    &-select {
      .node-labels-renderer__tag-label-btn {
        visibility: hidden;
      }
      .anticon-close {
        display: none;
      }
    }
    &-wrapper {
      display: flex;
      width: 100%;
      gap: 4px;
      justify-content: space-between;

      .rc-virtual-list {
        flex: 1;

        .rc-virtual-list-holder-inner {
          gap: 2px;
        }
      }

      .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
        font-weight: unset;
      }
    }
    &-popup {
      .ant-select-item.ant-select-item-option:has(
          .node-labels-renderer__tag-label-wrapper.selected
        ) {
        border: 1px solid;
        border-color: @inc-blue;
        border-radius: 4px;
      }
    }

    margin-inline: 4px;
    display: flex;
    flex-direction: column;
    gap: 8px;

    button {
      width: 100%;
    }
  }
  &__tag-label {
    &-wrapper {
      display: flex;
    }
    &-info {
      flex: 1;
    }
  }
}

@font-family: 'Lato', sans-serif;@primary-color: #5E81AC;@success-color: #A3BE8C;@error-color: #BF616A;@info-color: #5E81AC;@black: #2E3440;@white: #ECEFF4;@body-background: #ECEFF4;@layout-sider-background: #5E81AC;@menu-dark-bg: #5E81AC;@menu-dark-inline-submenu-bg: #5E81AC;