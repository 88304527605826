.cluster-size-main-info-step {
  &__role-label {
    display: flex;
    gap: 4px;
    align-items: center;
  }
  &__role-selection-popup {
    .ant-select-item.ant-select-item-option:not(
        .ant-select-item-option-selected
      ) {
      .cluster-size-main-info-step__role-selection-item {
        padding-inline-end: 14px;
      }
    }
  }
  &__role-selection-item {
    display: flex;
    justify-content: space-between;
    margin-inline-end: 8px;
    align-items: center;
  }
}

@font-family: 'Lato', sans-serif;@primary-color: #5E81AC;@success-color: #A3BE8C;@error-color: #BF616A;@info-color: #5E81AC;@black: #2E3440;@white: #ECEFF4;@body-background: #ECEFF4;@layout-sider-background: #5E81AC;@menu-dark-bg: #5E81AC;@menu-dark-inline-submenu-bg: #5E81AC;