@import '~styles/variables';

.email__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.email-sent-page__wrapper {
  display: grid;
  column-gap: 64px;
  grid-template-areas:
    'inc bg'
    'info bg';

  @media screen and (max-width: 765px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 2fr 2fr;
    grid-template-areas:
      'inc'
      'bg'
      'info';
  }

  .email-sent__incorta-logo {
    grid-area: inc;
    justify-self: right;
    align-self: end;
    right: calc(608px - 88px);
    position: relative;
  }

  .email-sent-page__info {
    max-width: 608px;
    justify-self: end;
    text-align: start;
    grid-area: info;
  }

  .email-sent-page__info h2 {
    font-family: 'Inter';
    font-weight: 600;
    font-size: 40px;
    line-height: 48px;
    color: @black;
    margin: 64px 0 24px 0;
  }

  .email-sent-page__info p {
    font-size: 20px;
    line-height: 24px;
  }

  .email-sent-page__info:nth-child(1) {
    margin-bottom: 24px;
  }

  .email-sent-page__resend-email,
  .email-sent-page__contact-support {
    display: inline;
    font-weight: 600;
    color: @inc-blue;
    text-decoration: none;
    cursor: pointer;
  }

  .email-sent-page__resend-email:hover,
  .email-sent-page__contact-support:hover {
    text-decoration: underline;
  }

  .submit-form-button__wrapper {
    margin: 16px 0;

    .submit-form-button {
      background-color: @inc-blue;
      color: @white;
      border: unset;
      border-radius: 4px;
      height: 48px;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      text-transform: uppercase;
      cursor: pointer;
    }
  }

  .email-sent__background-image {
    grid-area: bg;
    align-self: center;
  }

  @media screen and (max-width: 1512px) {
    .email-sent__background-image {
      width: 464.11px;
      height: 350px;
    }

    .email-sent-page__info {
      max-width: 553px;
    }

    .email-sent__incorta-logo {
      right: calc(553px - 88px);
    }
  }

  @media screen and (max-width: 1366px) {
    .email-sent__background-image {
      width: 461.14px;
      height: 347.06px;
    }
  }

  @media screen and (max-width: 1024px) {
    .email-sent-page__info {
      max-width: 402px;
    }

    .email-sent-page__info h2 {
      font-size: 32px;
    }

    .email-sent-page__info p {
      max-width: 372px;
      line-height: 24px;
    }

    .email-sent__incorta-logo {
      right: calc(402px - 88px);
    }

    .email-sent__background-image {
      width: 298.07px;
      height: 224.34px;
    }
  }

  @media screen and (max-width: 765px) {
    .email-sent__incorta-logo {
      grid-area: inc;
      right: unset;
      margin-left: 159px;
      justify-self: start;
    }

    .email-sent__background-image {
      width: 298.07px;
      height: 224.34px;
      grid-area: bg;
    }

    .email-sent-page__info,
    .email-sent__background-image {
      justify-self: center;
    }

    .email-sent-page__info h2 {
      margin-top: 0px;
      line-height: 29px;
    }

    .email-sent-page__info,
    .email-sent-page__info p {
      max-width: 447px;
      line-height: 19px;
    }
  }

  @media screen and (max-width: 375px) {
    .email-sent__incorta-logo {
      width: 65.41px;
      height: 72px;
      justify-self: start;
      margin-top: 87px;
      margin-left: 64px;
    }

    .email-sent__background-image {
      width: 247px;
      height: 185.9px;
    }

    .email-sent-page__info {
      max-width: 241px;
    }

    .email-sent-page__info h2 {
      font-size: 24px;
    }

    .email-sent-page__info p {
      font-size: 16px;
    }
  }
}

@font-family: 'Lato', sans-serif;@primary-color: #5E81AC;@success-color: #A3BE8C;@error-color: #BF616A;@info-color: #5E81AC;@black: #2E3440;@white: #ECEFF4;@body-background: #ECEFF4;@layout-sider-background: #5E81AC;@menu-dark-bg: #5E81AC;@menu-dark-inline-submenu-bg: #5E81AC;