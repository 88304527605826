@import '~styles/variables';

.cluster-size-cell-modal {
  &__title-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-inline-end: 32px;
  }

  &__footer-wrapper {
    display: flex;
    justify-content: space-between;
  }
}

.steps-content {
  &__wrapper {
    height: 500px;
    padding-block-start: 8px;
    // overflow-y: auto;
  }
  height: 100%;

  // .ant-row,
  // .ant-col.ant-col-18,
  .ant-table-wrapper {
    height: 100%;
  }
  .ant-table-content {
    max-height: 500px;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 7px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: @slate-gray;
      -webkit-box-shadow: 0 0 1px #ffffff80;
    }
  }
}

@font-family: 'Lato', sans-serif;@primary-color: #5E81AC;@success-color: #A3BE8C;@error-color: #BF616A;@info-color: #5E81AC;@black: #2E3440;@white: #ECEFF4;@body-background: #ECEFF4;@layout-sider-background: #5E81AC;@menu-dark-bg: #5E81AC;@menu-dark-inline-submenu-bg: #5E81AC;