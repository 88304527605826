.feature-bit-option {
  display: flex;
  justify-content: space-between;
  align-items: center;
  > span:first-of-type {
    margin-right: 1em;
  }
  > span:last-of-type {
    margin-left: 1em;
  }
}

@font-family: 'Lato', sans-serif;@primary-color: #5E81AC;@success-color: #A3BE8C;@error-color: #BF616A;@info-color: #5E81AC;@black: #2E3440;@white: #ECEFF4;@body-background: #ECEFF4;@layout-sider-background: #5E81AC;@menu-dark-bg: #5E81AC;@menu-dark-inline-submenu-bg: #5E81AC;