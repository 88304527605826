.count-info-running {
  display: flex;
  align-items: center;
  gap: 5px;
}

.count-info-upgrading {
  display: flex;
  align-items: center;
  gap: 5px;
}

.count-info-service-down {
  display: flex;
  align-items: center;
  gap: 5px;
}

.count-info-being-created {
  display: flex;
  align-items: center;
  gap: 5px;
}

.count-info-starting {
  display: flex;
  align-items: center;
  gap: 5px;
}
.count-info-aborted {
  display: flex;
  align-items: center;
  gap: 5px;
}

.running-clusters-square {
  background-color: green;
  height: 15px;
  width: 15px;
  border-bottom: black;
}
.running-cluster-text {
  display: inline;
}

.upgrading-clusters-square {
  background-color: #edc949;
  height: 15px;
  width: 15px;
  border-bottom: black;
}
.upgrading-cluster-text {
  display: inline;
}

.service-down-clusters-square {
  background-color: #e15759;
  height: 15px;
  width: 15px;
  border-bottom: black;
}
.service-down-cluster-text {
  display: inline;
}

.clusters-being-created-square {
  background-color: #b4efbee1;
  height: 15px;
  width: 15px;
  border-bottom: black;
}
.clusters-being-created-text {
  display: inline;
}

.starting-clusters-square {
  background-color: #8cc6ea;
  height: 15px;
  width: 15px;
  border-bottom: black;
}
.starting-cluster-text {
  display: inline;
}
.aborted-clusters-square {
  background-color: orange;
  height: 15px;
  width: 15px;
  border-bottom: black;
}
.aborted-cluster-text {
  display: inline;
}

.clusters-card {
  width: 100%;
}
.card {
  display: flex;
  .running {
    background-color: green;
    padding: 10px;
    margin: 1px 1px 0 0;
    width: 50px;
    height: 50px;
    border-top: black;
    .name {
      font-size: 10px;
      font-weight: normal;
      overflow: hidden;
      border-bottom: black;
      display: -webkit-box;
    }
  }
  .loading {
    background-color: #edc949;
    padding: 10px;
    margin: 1px 1px 0 0;
    width: 50px;
    height: 50px;
    border-top: black;
    .name {
      font-size: 10px;
      font-weight: normal;
      overflow: hidden;
      border-bottom: black;
      display: -webkit-box;
    }
  }
  .service-down {
    background-color: #e15759;
    padding: 10px;
    margin: 1px 1px 0 0;
    width: 50px;
    height: 50px;
    border-top: black;
    .name {
      font-size: 10px;
      font-weight: normal;
      overflow: hidden;
      border-bottom: black;
      display: -webkit-box;
    }
  }
  .creating-cluster {
    background-color: #b4efbee1;
    padding: 10px;
    margin: 1px 1px 0 0;
    width: 50px;
    height: 50px;
    border-top: black;
    .name {
      font-size: 10px;
      font-weight: normal;
      overflow: hidden;
      border-bottom: black;
      display: -webkit-box;
    }
  }
}

.starting {
  background-color: #8cc6ea;
  padding: 10px;
  margin: 1px 1px 0 0;
  width: 50px;
  height: 50px;
  border-top: black;
  .name {
    font-size: 10px;
    font-weight: normal;
    overflow: hidden;
    border-bottom: black;
    display: -webkit-box;
  }
}
.aborted {
  background-color: orange;
  padding: 10px;
  margin: 1px 1px 0 0;
  width: 50px;
  height: 50px;
  border-top: black;
  .name {
    font-size: 10px;
    font-weight: normal;
    overflow: hidden;
    border-bottom: black;
    display: -webkit-box;
  }
}

.ClusterInsightsModal {
  width: fit-content !important;
}

@font-family: 'Lato', sans-serif;@primary-color: #5E81AC;@success-color: #A3BE8C;@error-color: #BF616A;@info-color: #5E81AC;@black: #2E3440;@white: #ECEFF4;@body-background: #ECEFF4;@layout-sider-background: #5E81AC;@menu-dark-bg: #5E81AC;@menu-dark-inline-submenu-bg: #5E81AC;