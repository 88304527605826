@import '~styles/variables';

.cluster-form {
  &__region-select-wrapper {
    .ant-select-selection-item {
      display: flex;
      gap: 32px;
    }
  }
  &__region-select-group-wrapper {
    display: flex;
    align-items: center;
    gap: 4px;
    font-weight: 600;
    color: @black;
    line-height: 26px;
    border-block-start: 1px solid @slate-gray;
    border-block-end: 1px solid @solitude-gray;
  }
  &__region-select-group-img {
    height: 16px;
  }
  &__region-select-item {
    .ant-select-item-option-content {
      display: flex;
      justify-content: space-between;
    }
  }
}

@font-family: 'Lato', sans-serif;@primary-color: #5E81AC;@success-color: #A3BE8C;@error-color: #BF616A;@info-color: #5E81AC;@black: #2E3440;@white: #ECEFF4;@body-background: #ECEFF4;@layout-sider-background: #5E81AC;@menu-dark-bg: #5E81AC;@menu-dark-inline-submenu-bg: #5E81AC;