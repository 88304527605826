.tableTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.link {
  cursor: pointer;
  color: #087ca7;
  padding: 0;
  border: 0;
  background: none;

  &:hover,
  &:focus {
    text-decoration: underline;
  }

  &[disabled] {
    color: #99a6b0;
    cursor: not-allowed;
    text-decoration: none;
  }
}

.spinner-container {
  > .ant-spin {
    width: 100%;
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.search-field {
  display: flex;
  button {
    height: 32px;
  }
  .ant-btn-primary {
    margin-right: 5px;
  }
}


.reset-Button {
  cursor: pointer;
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;

  background: transparent;

  /* inherit font & color from ancestor */
  color: inherit;
  font: inherit;

  /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
  line-height: normal;

  /* Corrects font smoothing for webkit */
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;

  /* Corrects inability to style clickable `input` types in iOS */
  -webkit-appearance: none;
}

.simple-padding {
  padding: 4px;
}

@font-family: 'Lato', sans-serif;@primary-color: #5E81AC;@success-color: #A3BE8C;@error-color: #BF616A;@info-color: #5E81AC;@black: #2E3440;@white: #ECEFF4;@body-background: #ECEFF4;@layout-sider-background: #5E81AC;@menu-dark-bg: #5E81AC;@menu-dark-inline-submenu-bg: #5E81AC;