@import '~styles/variables';

.gmonitor-container {
  display: flex;
  gap: 10px;
  background-color: white;
  padding: 16px;
  border-radius: 5px;
  margin-bottom: 50px;
  h4 {
    font-size: 18px;
  }
  .anticon {
    font-size: 18px;
    font-weight: bold;
    padding-top: 4px;
    color: black;
  }
  .gmonitor-info {
    display: flex;
    align-items: baseline;
    .ant-typography:first-child {
      width: 150px;
    }
    .ant-typography {
      margin-top: 0 !important;
    }
    .ant-typography:nth-child(2) {
      user-select: none;
      filter: blur(5px);
    }
  }

  .reveal-credentials-button {
    display: flex;
    align-items: flex-end;
    svg {
      color: @white;
    }
  }
}
.gmonitor-credentials-modal {
  &__wrapper {
    .gmonitor-info {
      display: flex;
      align-items: baseline;
      .ant-typography:first-child {
        width: 150px;
      }
      .ant-typography {
        margin-block: 5px;
      }

      .copy-icon {
        margin-inline: auto 0;
      }
    }
  }
}
.count-section {
  display: grid;
  grid-template-columns: repeat(4, 1fr);

  grid-gap: 16px;
  .count-info {
    display: flex;
    padding: 16px;
    align-items: center;
    gap: 5px;
    background-color: white;
    border-radius: 5px;

    .anticon {
      color: white;
      /* padding: 10px 7px; */
      border-radius: 50%;
      width: 50px;
      height: 50px;
      padding-top: 11px;
      padding-left: 9px;
      svg {
        font-size: 30px;
        margin-right: 10px;
      }
    }
    .count {
      font-size: 25px;
      font-weight: bold;
      color: #777b82;
      display: block;
    }
  }

  .running-cluster {
    border-top: 5px solid #76b7b2;
    .anticon {
      background-color: #76b7b2;
    }
  }
  .created-cluster {
    border-top: 5px solid #edc949;

    .anticon {
      background-color: #edc949;
    }
  }
  .verified-user {
    border-top: 5px solid #59a14f;

    .anticon {
      background-color: #59a14f;
    }
  }
  .unverified-user {
    border-top: 5px solid #e15759;

    .anticon {
      background-color: #e15759;
    }
  }
}
.clusters-card,
.users-card {
  margin-top: 50px;
}
.card {
  display: flex;
  flex-wrap: wrap;
  .card-container {
    background-color: white;
    padding: 10px;
    margin: 16px 12px 0 0;
    width: 19.2%;
    border-radius: 5px;
    span {
      display: inline-block;
    }
    .anticon {
      margin-right: 10px;
    }
    .name {
      font-size: 18px;
      font-weight: bold;
      border-bottom: 3px solid #eceff4;
      margin-bottom: 10px;
      padding-bottom: 5px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
  }
}

.home-container {
  .card {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, auto));
    grid-gap: 16px;
  }
  .card-container {
    width: auto;
    margin: 0px;
  }
}

@font-family: 'Lato', sans-serif;@primary-color: #5E81AC;@success-color: #A3BE8C;@error-color: #BF616A;@info-color: #5E81AC;@black: #2E3440;@white: #ECEFF4;@body-background: #ECEFF4;@layout-sider-background: #5E81AC;@menu-dark-bg: #5E81AC;@menu-dark-inline-submenu-bg: #5E81AC;