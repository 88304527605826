.NavigationLayout {
  min-height: 100vh;
  &__logo {
    height: 85px;
    padding: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 100%;
    }
  }

  &__rightheader {
    background: #fff;
    display: flex;
    justify-content: flex-end;
  }

  &__header {
    background: #fff;
    padding: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__header-nav {
    > *:not(:last-child) {
      margin-right: 12px;
    }
  }

  &__trigger {
    font-size: 18px;
    line-height: 64px;
    cursor: pointer;
    transition: color 0.3s;

    &:hover {
      color: #5e81ac;
    }
  }

  &__content {
    margin: 20px;
    padding: 20px;
  }
}

@font-family: 'Lato', sans-serif;@primary-color: #5E81AC;@success-color: #A3BE8C;@error-color: #BF616A;@info-color: #5E81AC;@black: #2E3440;@white: #ECEFF4;@body-background: #ECEFF4;@layout-sider-background: #5E81AC;@menu-dark-bg: #5E81AC;@menu-dark-inline-submenu-bg: #5E81AC;