.Notifcations {
  .num-of-selected {
    margin-right: 10px;
    border: 1px solid #cecbcb;
    padding: 4px;
    border-radius: 4px;
  }
}

.notificationInfo {
  background-color: #eceff4;
  padding: 20px 10px;
  border-radius: 5px;
  margin-bottom: 20px;
  p {
    margin-bottom: 0;
    font-weight: 500;
    .anticon {
      margin-right: 7px;
    }
  }
}

@font-family: 'Lato', sans-serif;@primary-color: #5E81AC;@success-color: #A3BE8C;@error-color: #BF616A;@info-color: #5E81AC;@black: #2E3440;@white: #ECEFF4;@body-background: #ECEFF4;@layout-sider-background: #5E81AC;@menu-dark-bg: #5E81AC;@menu-dark-inline-submenu-bg: #5E81AC;