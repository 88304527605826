.dynamic-advanced-search-modal {
  &__wrapper {
    .dynamic-advanced-search-modal__search-value-select-wrapper {
      .ant-select {
        width: 100%;
      }
    }
  }
  &__search-key-select {
    width: 100%;
  }
}

@font-family: 'Lato', sans-serif;@primary-color: #5E81AC;@success-color: #A3BE8C;@error-color: #BF616A;@info-color: #5E81AC;@black: #2E3440;@white: #ECEFF4;@body-background: #ECEFF4;@layout-sider-background: #5E81AC;@menu-dark-bg: #5E81AC;@menu-dark-inline-submenu-bg: #5E81AC;