.NewProvisionCustomer {
  display: flex;
  justify-content: center;
}

.NewProvisionCustomer__collapse_container {
  max-width: 860px;
  width: 100%;
}

.NewProvisionCustomer__actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.NewProvisionCustomer__panel-wrapper {
  width: 100%;
  padding: 10px 0;
}

.Collapse {
  width: 100%;

  .ant-collapse-item:not(:last-of-type) {
    margin-bottom: 10px;
  }

  & > .ant-collapse-item > .ant-collapse-header {
    padding: 0;
    margin-bottom: 1px;
  }

  .ant-collapse-header-text {
    width: 100%;
    padding: 18px 20px;
    background: white;
    border-radius: 4px;
    font-weight: 600;
    font-size: 16px;
  }

  .Collapse__panel-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .Collapse__panel-header-actions {
    display: flex;
    gap: 8px;
    align-items: center;
  }

  .ant-collapse-content-box {
    padding: 18px 20px;
    background: white;
    border-radius: 4px;
    display: flex;
    justify-content: center;
  }

  // .ant-collapse-header {
  //   // flex-direction: row-reverse;
  //   // justify-content: space-between;
  // }
}

.InnerCollapse {
  .ant-collapse-item {
    border: 1px solid #979797 !important;
  }

  .ant-collapse-header {
    padding: 0 !important;
  }

  .ant-collapse-content-box {
    width: 100%;
    display: block;
  }
}

@font-family: 'Lato', sans-serif;@primary-color: #5E81AC;@success-color: #A3BE8C;@error-color: #BF616A;@info-color: #5E81AC;@black: #2E3440;@white: #ECEFF4;@body-background: #ECEFF4;@layout-sider-background: #5E81AC;@menu-dark-bg: #5E81AC;@menu-dark-inline-submenu-bg: #5E81AC;