@import '~styles/variables';

.new-partners-modal {
  &__title {
    display: flex;
    gap: 4px;
  }
  &__section {
    margin-block-end: 8px;

    &-title {
      display: flex;
      justify-content: center;
      gap: 4px;

      img {
        width: 24px;
      }
    }
  }
  &__ml-flow-form-item {
    img {
      height: 16px;
      margin-inline-end: 4px;
    }
  }
  &__platform-wrapper {
    display: flex;
    gap: 4px;
    align-items: center;
  }
  &__platform-select-group-img {
    width: 16px;
    filter: grayscale(80%);
  }

  &__region-select-img {
    height: 16px;
  }
  &__supported-regions-select-label {
    display: flex;
    align-items: center;
    gap: 4px;
    font-weight: 600;
    color: @black;
    line-height: 26px;
    border-block-start: 1px solid @slate-gray;
    border-block-end: 1px solid @solitude-gray;
  }
  &__region-select-item {
    .ant-select-item-option-content {
      display: flex;
      justify-content: space-between;
    }
  }
}

@font-family: 'Lato', sans-serif;@primary-color: #5E81AC;@success-color: #A3BE8C;@error-color: #BF616A;@info-color: #5E81AC;@black: #2E3440;@white: #ECEFF4;@body-background: #ECEFF4;@layout-sider-background: #5E81AC;@menu-dark-bg: #5E81AC;@menu-dark-inline-submenu-bg: #5E81AC;