.Clusters {
  td {
    min-width: 80px;
  }
  .cluster-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .num-of-selected {
      border: 1px solid #d4d4d4;
      padding: 3px;
      border-radius: 4px;
      .anticon-close {
        font-size: 11px;
        cursor: pointer;
        margin-left: 4px;
      }
    }
  }
  .search-field {
    display: flex;
    button {
      height: 32px;
    }
    .ant-btn-primary {
      margin-right: 5px;
    }
  }
}
@import '~styles/variables';

.role-modal {
  &__wrapper {
    .pages-form-item__container {
      max-height: 350px;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 7px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: @slate-gray;
        -webkit-box-shadow: 0 0 1px #ffffff80;
      }
    }

    .ant-radio-button-wrapper {
      &.page-permission__1 {
        background-color: @solitude-gray;
        color: @black;
      }
      &.page-permission__2 {
        background-color: @solitude-gray;
        color: @black;
      }
      &.page-permission__3 {
        background-color: @solitude-gray;
        color: @black;
      }

      &.ant-radio-button-wrapper.ant-radio-button-wrapper-checked,
      &:hover {
        background-color: @antd-blue;
        color: @white;
      }
      &.ant-radio-button-wrapper-checked {
        font-weight: 600;
      }
    }
  }

  &__must-add-action-or-page {
    display: flex;
    gap: 8px;
    align-items: baseline;
    margin-block-end: 16px;
  }

  &__field {
    &-label {
      display: inline-flex;
    }
    &-hint {
      margin-inline: 16px;
      font-size: 12px;
      margin-block-start: 3px;
      display: flex;
      align-items: baseline;
      gap: 4px;
    }
  }

  &__pages-table-column-title {
    display: flex;
    gap: 8px;
    align-items: baseline;

    &-hint {
      font-size: 12px;
    }
  }
}

.restore {
  &__size-cell {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 8px;
    &.center {
      justify-content: center;
    }
  }
}

@font-family: 'Lato', sans-serif;@primary-color: #5E81AC;@success-color: #A3BE8C;@error-color: #BF616A;@info-color: #5E81AC;@black: #2E3440;@white: #ECEFF4;@body-background: #ECEFF4;@layout-sider-background: #5E81AC;@menu-dark-bg: #5E81AC;@menu-dark-inline-submenu-bg: #5E81AC;