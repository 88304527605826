@import '~styles/variables';

// mixin
.flex-gap4-aligncenter() {
  display: flex;
  gap: 4px;
  align-items: center;
}

.partners {
  &__platform {
    &-cell {
      display: flex;
      gap: 4px;
      text-transform: uppercase;
    }
    &-img {
      width: 16px;
    }
  }
  &__cluster-size-cell {
    text-transform: capitalize;
  }

  &__spark-quotas-cell {
    .flex-gap4-aligncenter();
    justify-content: center;

    svg {
      height: 24px;
      width: 24px;
    }
  }
  &__max-cpu-cell,
  &__max-memory-cell {
    .flex-gap4-aligncenter();
    justify-content: center;

    svg {
      height: 16px;
      color: @inc-blue;
    }
  }
  &__mlflow-cell {
    img {
      height: 16px;
    }
  }
  &__region-cell {
    .flex-gap4-aligncenter();
  }

  &__advanced-search-platform-select-label {
    .flex-gap4-aligncenter();
    font-weight: 600;
    color: @black;
    line-height: 26px;
  }
  &__platform-select-img {
    height: 16px;
  }

  &__expanded-row-table {
    margin-block-end: 24px;
    display: flex;
    justify-content: center;
    align-items: start;

    div:first-child {
      width: 300px;
    }
  }
}

@font-family: 'Lato', sans-serif;@primary-color: #5E81AC;@success-color: #A3BE8C;@error-color: #BF616A;@info-color: #5E81AC;@black: #2E3440;@white: #ECEFF4;@body-background: #ECEFF4;@layout-sider-background: #5E81AC;@menu-dark-bg: #5E81AC;@menu-dark-inline-submenu-bg: #5E81AC;