.auto-scaling-modal {
  &__title {
    display: flex;
    align-items: center;
    gap: 8px;

    img {
      width: 16px;
    }
  }
  &__status {
    font-size: 14px;
    font-weight: 600;
  }
  &__change-autoscaling-hint {
    display: flex;
    gap: 8px;
    align-items: baseline;
  }
  &__label {
    display: flex;
    gap: 8px;
    align-items: baseline;
  }
}

@font-family: 'Lato', sans-serif;@primary-color: #5E81AC;@success-color: #A3BE8C;@error-color: #BF616A;@info-color: #5E81AC;@black: #2E3440;@white: #ECEFF4;@body-background: #ECEFF4;@layout-sider-background: #5E81AC;@menu-dark-bg: #5E81AC;@menu-dark-inline-submenu-bg: #5E81AC;