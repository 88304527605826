@import '~styles/variables';

.cloud-capacity-management-table__size-cell {
  padding: 4px 8px; // Reduce padding
  font-size: 12px; // Smaller text
}

.cloud-capacity-management-table__memory-cell-card-wrapper {
  padding: 8px; // Adjust to match overall cell dimensions
}

.cloud-capacity-management-table__overcommitment-cell-tag {
  margin: 4px 0; // Reduce margins
}





.cloud-capacity-management-table {
  &__size-field-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    color: #595959;
  }

  &__size-cell {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    color: #262626;
    padding: 5px 10px;
  }

  &__memory-cell-card-wrapper {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 2px;
    border: 1px solid #aba9a9;
    border-radius: 5px;
    background-color: #f6f9fc;
  }
  

  .responsive-table {
    .ant-table-thead > tr > th {
      font-size: 12px; // Smaller header font
      padding: 8px; // Less padding
    }
    .ant-table-tbody > tr > td {
      font-size: 12px;
      padding: 8px;
    }
  }
  &__memory-cell-card {
    display: flex;
    flex-direction: column;
  }

  &__memory-cell-card-header {
    font-size: 12px;
    font-weight: bold;
    color: #595959;
  }

  &__memory-cell-card-details {
    font-weight: bold;
  }

  &__memory-cell-card-progress-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__memory-cell-card-title {
    font-size: 12px;
    font-weight: 500;
  }

  &__memory-cell-card-data {
    display: flex;
    align-items: center;
    font-size: 12px;
  }

  &__cell-value {
    font-weight: 600;
  }

  &__cell-unit {
    font-size: 10px;
    color: #8c8c8c;
  }

  .cloud-capacity-management-table {
    &__overcommitment-cell-tag {
      font-size: 10px; /* Reduced font size */
      padding: 2px 6px; /* Smaller padding */
      border-radius: 8px; /* Slightly smaller border radius */
      margin-top: 4px; /* Reduced margin */
      display: inline-flex;
      align-items: center;
  
      &.green {
        background-color: #f6ffed;
        color: #52c41a;
      }
  
      &.red {
        background-color: #fff1f0;
        color: #f5222d;
      }
  
      svg {
        font-size: 12px; /* Icon size adjusted */
        margin-right: 4px; /* Reduced spacing between icon and text */
      }
    }
  }
}

@font-family: 'Lato', sans-serif;@primary-color: #5E81AC;@success-color: #A3BE8C;@error-color: #BF616A;@info-color: #5E81AC;@black: #2E3440;@white: #ECEFF4;@body-background: #ECEFF4;@layout-sider-background: #5E81AC;@menu-dark-bg: #5E81AC;@menu-dark-inline-submenu-bg: #5E81AC;