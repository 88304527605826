.form-item-row {
  display: flex;
  gap: 5px;
  .form-item-select-default {
    width: 27%;
  }
  .form-item-input-default {
    width: 65%;
  }
  .form-item-select {
    width: 30%;
  }
  .form-item-input {
    width: 70%;
  }

  .anticon-minus-circle {
    font-size: 19px;
    margin-top: 6px;
    margin-left: 10px;
  }
}

@font-family: 'Lato', sans-serif;@primary-color: #5E81AC;@success-color: #A3BE8C;@error-color: #BF616A;@info-color: #5E81AC;@black: #2E3440;@white: #ECEFF4;@body-background: #ECEFF4;@layout-sider-background: #5E81AC;@menu-dark-bg: #5E81AC;@menu-dark-inline-submenu-bg: #5E81AC;