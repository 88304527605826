@import '~styles/variables';

.chidori-sizes {
  &__wrapper {
    .ant-slider-track {
      background-color: @inc-hover-blue;
    }
    .ant-slider-rail {
      background-color: @slate-gray;
    }
    .ant-slider-dot {
      border-color: @slate-gray;
    }

    .ant-slider-handle {
      background-color: @inc-blue;
    }

    .mark {
      &__wrapper {
        display: flex;
        flex-direction: column;
      }
      &__mark-name {
        font-size: 12px;
      }

      &__mark-original {
        font-size: 10px;
      }
    }
  }
  &__items {
    margin-block-start: 32px;
  }
  &__item {
    margin-block-end: 12px;
    // background-color: @solitude-gray;
    &-info {
      display: flex;
      gap: 5px;
      align-items: baseline;

      h3 {
        border-inline-end: 2px solid @slate-gray;
        padding-inline-end: 5px;
        margin-block-end: unset;
        font-weight: 600;
      }
      label {
        color: @inc-hover-blue;
        font-weight: 600;
      }
    }
  }
}

@font-family: 'Lato', sans-serif;@primary-color: #5E81AC;@success-color: #A3BE8C;@error-color: #BF616A;@info-color: #5E81AC;@black: #2E3440;@white: #ECEFF4;@body-background: #ECEFF4;@layout-sider-background: #5E81AC;@menu-dark-bg: #5E81AC;@menu-dark-inline-submenu-bg: #5E81AC;