@import '~styles/variables';

.cloud-capacity-management {
  &__wrapper {
    display: flex;
    flex-direction: column;
  }
  &__wrapper,
  &__tabs {
    height: 100%;
    .ant-tabs-content-holder,
    .ant-tabs-content.ant-tabs-content-top,
    .ant-tabs-tabpane {
      height: 100%;
      text-align: center;
    }
  }
}

@font-family: 'Lato', sans-serif;@primary-color: #5E81AC;@success-color: #A3BE8C;@error-color: #BF616A;@info-color: #5E81AC;@black: #2E3440;@white: #ECEFF4;@body-background: #ECEFF4;@layout-sider-background: #5E81AC;@menu-dark-bg: #5E81AC;@menu-dark-inline-submenu-bg: #5E81AC;