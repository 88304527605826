@import '~styles/variables';

.error-page {
  &__wrapper {
    display: grid;
    grid-template-rows: 1fr 1fr;
    gap: 64px;

    position: relative;
    height: 100vh;
  }

  &__info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-inline: 36px;
    margin-block: 64px;
  }

  &__logo-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
  }
  &__logo {
    width: 100%;
  }
  &__incorta-logo {
    height: 67px;
    width: 65px;
    margin-block-end: 32px;
  }
  &__header {
    margin-block-end: 24px;
    font-family: Montserrat, Arial;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    display: flex;
    align-items: center;
    text-align: center;
    color: @charcoal;
  }
  &__sub-header {
    font-family: Inter, Arial;
    font-size: 18px;
    line-height: 22px;
    text-align: center;

    a {
      color: @inc-blue;
      font-weight: 600;
    }
  }
  &__home-page-button {
    margin-block: 12px;
  }

  @media screen and (min-width: 760px) {
    &__wrapper {
      display: grid;
      grid-template-rows: 1fr;
      grid-template-columns: 1fr 1fr;
    }
    &__logo {
      height: 100%;
      width: 100%;
      max-height: 504px;
      max-width: 571px;
    }
    &__logo-wrapper {
      flex-direction: column;
    }
    &__incorta-logo {
      height: unset;
      width: unset;
    }
    &__header {
      font-weight: 600;
      font-size: 40px;
      font-size: clamp(24px, 2vw, 40px);
      line-height: clamp(29px, 2.5vw, 48px);
      margin-block-end: 8px;
    }
    &__sub-header {
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
    }
  }
}

@font-family: 'Lato', sans-serif;@primary-color: #5E81AC;@success-color: #A3BE8C;@error-color: #BF616A;@info-color: #5E81AC;@black: #2E3440;@white: #ECEFF4;@body-background: #ECEFF4;@layout-sider-background: #5E81AC;@menu-dark-bg: #5E81AC;@menu-dark-inline-submenu-bg: #5E81AC;